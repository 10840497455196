/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import Rypxradio from "../../../common/zzjg/Rypxradio";
import Fxlxradio from "../../../common/yfcs/Fxlxradio";
import { RxUtil } from "@/assets/util.js";
import { format } from "@/assets/app.js";
export default {
  name: "lcfqPcsq",
  components: {
    LCFQheader,
    Rypxradio,
    Fxlxradio
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "风险管理计划",
      flagBs: "风险管理计划",
      showListpage: false,
      showlxpage: false,
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      showGroupDialog: false,
      nodeGroupVm: {},
      userID: "",
      solId: "",
      boDefId: "",
      formKey: "",
      data: {
        bh: "",
        fxlx: "",
        fxyjtnr: "",
        ydcs: "",
        ydsj: ''
      },
      pxxzKey: "",
      pxlxKey: "",
      gwpxKey: ""
    };
  },
  created() {
    this.initBySolInstId();
    this.cgORxj();
  },
  methods: {
    initBySolInstId() {
      let newDate = new Date();
      this.data.ssjhrq = format(newDate);
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(res => {
        this.solId = res.data.bpmSolution.solId;
        this.boDefId = res.data.formModels[0].boDefId;
        this.formKey = res.data.formModels[0].formKey;
        // this.data.sqbh = res.data.formModels[0].jsonData.sqbh;
        // let zrr = res.data.formModels[0].jsonData.zrr;
        // let zrr_name = res.data.formModels[0].jsonData.zrr_name;
        // let zrrObj = [{id:zrr,text:zrr_name}];
        // this.data.zrr = JSON.stringify(zrrObj);
        // let bm = res.data.formModels[0].jsonData.bm;
        // let bm_name = res.data.formModels[0].jsonData.bm_name;
        // let bmObj = [{id:bm,text:bm_name}];
        // this.data.bm = JSON.stringify(bmObj);
      });
    },

    async cgORxj() {
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";

        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        console.log(user_No);
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No; //+"&instId="+instId
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);
          this_.data.zrr = this.setSelectData(resData.zrr, resData.zrr_name);
          this_.data.bm = this.setSelectData(resData.bm, resData.bm_name);
          this_.data.bh = resData.bh;
          this_.data.fxlx = resData.fxlx;
          this_.data.fxyjtnr = resData.fxyjtnr;
          this_.data.ydcs = resData.ydcs;
          this_.data.ydsj = resData.ydsj;
          console.log(this_.data);
        });
        /***/
        //     var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids="+instId;
        // this.$ajax.post(url).then(res=>{
        // 	this.data = res.data;
        //       let urlUser1 = _baseUrl + `/mobile/oa/getUsersSearch.do?pageIndex=0&pageSize=20&searchVal=${dlrseach}`;
        //       this.$ajax.post(urlUser1).then(resdlr=>{
        //       let dlr = [{id:resdlr.data.rowList[0].userId,text:resdlr.data.rowList[0].fullname}];
        //         this.data.dlrName = JSON.stringify(dlr);
        //       })
        // });
      } else {
        //this.initBySolInstId();
      }
    },
    setSelectData(strId, strName) {
      let zzzObj = [{
        id: strId,
        text: strName
      }];
      return JSON.stringify(zzzObj);
    },
    //选代理人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    //科室
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    tdObj(item) {
      this.data.bh = item.F_JHBH;
      this.showListpage = false;
    },
    fxlxObj(item) {
      this.data.fxlx = item.F_BH;
      this.showlxpage = false;
    },
    datas() {
      let zrr = JSON.parse(this.data.zrr);
      let bm = JSON.parse(this.data.bm);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            bh: this.data.bh,
            fxlx: this.data.fxlx,
            fxyjtnr: this.data.fxyjtnr,
            ydsj: this.data.ydsj,
            ydcs: this.data.ydcs,
            bm: bm[0].id,
            bm_name: bm[0].text,
            zrr: zrr[0].id,
            zrr_name: zrr[0].text
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.data.bh) || this.iN(this.data.fxlx) || this.iN(this.data.bm) || this.iN(this.data.zrr)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        console.log(res);
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败或信息未填完整！", "1800");
        }
      });
    },
    onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败或信息未填完整！", "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};
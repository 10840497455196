/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import { unique } from "@/assets/app.js";
import { Dialog } from 'vant';
export default {
  components: {},
  data() {
    return {
      searchVal: "",
      data: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 20,
      result: {}
    };
  },
  created() {
    this.getCgwpInfo();
    this.data = this.mount;
  },
  mounted() {},
  methods: {
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      let formdata = new FormData();
      formdata.append("pageIndex", pageNum);
      formdata.append("pageSize", this.pageSize);
      if (this.searchVal != "") {
        formdata.append("Q_F_BH_S_LK", this.searchVal);
      }
      var url = _baseUrl + `/dev/cus/customData/fxyj1/getData.do?single=true&_winid=w3334&_t=962775`;
      this.$ajax.post(url, formdata).then(res => {
        this.data = res.data.data;
        this.data.map(item => {
          if (JSON.stringify(this.mount).indexOf(item.identifyLabel) == -1) {
            this.mount.push(item);
          }
        });
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / 20);
        console.log(this.mount.length);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onChange() {
      var pageNum = this.pageNum;
      var maxPageNum = this.maxPageNum;
      if (pageNum <= maxPageNum) {
        // this.prePage = pageNum;
        this.getCgwpInfo();
      } else {
        Dialog({
          message: '请正确输入页数！'
        });
      }
    },
    //搜索
    onSearch() {
      this.pageNum = 1;
      this.getCgwpInfo();
    },
    onClear() {
      this.pageNum = 1;
      this.searchVal = "";
      this.getCgwpInfo();
    },
    //确定
    onSelects() {
      this.$emit("fxlxObj", this.result);
    }
  }
};
/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
// import { getTestTable } from "../../api/index";

import { unique } from "@/assets/app.js";
import { Dialog } from 'vant';
// import CPHinfo from "./CPHinfo"
export default {
  components: {

    // CPHinfo
  },
  props: {
    flagBs: {
      default: ""
    }
  },
  data() {
    return {
      searchVal: "",
      // showInfo:false,
      data: [],
      mount: [],
      mountDupRe: [],
      pageNum: 1,
      prePage: 1,
      maxPageNum: 1,
      totalNum: 0,
      // pageIndex: 0,
      pageSize: 20,
      result: {},
      itemString: ""
      // gysInfoData:{},
    };
  },

  created() {
    this.getCgwpInfo();
    this.data = this.mount;
    this.onEnter();
  },
  mounted() {},
  methods: {
    getCgwpInfo() {
      var pageNum = this.pageNum - 1;
      var url;
      if (this.flagBs == "人员监督/能力监控计划") {
        url = _baseUrl + `/dev/cus/customData/jdjhwd/getData.do?single=true&_winid=w4076&_t=571208`;
      }
      if (this.flagBs == "风险管理计划") {
        url = _baseUrl + "/dev/cus/customData/jhgl1/getData.do?single=true&_winid=w79&_t=137212";
      } else {
        url = _baseUrl + `/dev/cus/customData/jhwd/getData.do?single=true&_winid=w630&_t=607848`;
      }
      let formdata = new FormData();
      formdata.append("pageIndex", pageNum);
      formdata.append("pageSize", this.pageSize);
      this.$ajax.post(url, formdata).then(res => {
        this.data = res.data.data;
        this.data.map(item => {
          if (JSON.stringify(this.mount).indexOf(item.ID_) == -1) {
            this.mount.push(item);
          }
        });
        this.totalNum = res.data.total;
        this.maxPageNum = Math.ceil(this.totalNum / 20);
        console.log(this.mount.length);
      });
    },
    onPlus() {
      this.pageNum += 1;
      this.getCgwpInfo();
    },
    onMinus() {
      this.pageNum -= 1;
      this.getCgwpInfo();
    },
    onEnter() {
      document.onkeydown = e => {
        if (e.keyCode === 13) {
          var pageNum = this.pageNum;
          var maxPageNum = this.maxPageNum;
          if (pageNum <= maxPageNum) {
            // this.prePage = pageNum;
            this.getCgwpInfo();
          } else {
            Dialog({
              message: '请正确输入页数！'
            });
          }
        }
      };
    },
    // onInfo(item) {
    //   console.log(item);
    //   this.showInfo = true;
    //   this.gysInfoData = item;
    // },

    //确定
    onSelects() {
      // console.log(this.result)
      this.$emit("tdObj", this.result);
    }
  }
};